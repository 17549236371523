<template>
  <div
    class="d-flex flex-column"
    style="
      min-width: 260px;
      max-width: 260px;
      gap: 12px;
      border-right: 1px solid var(--v-background_dark-base);
      border-bottom: 1px solid var(--v-background_dark-base);
      height: 100vh;
    "
  >
    <div
      class="d-flex px-3 py-4 justify-space-between"
      style="border-bottom: 1px solid var(--v-background_dark-base)"
      v-if="!search"
    >
      <div class="d-flex align-center" style="gap: 8px">
        <v-btn icon x-small @click="toggleSelectAll()">
          <feather
            :type="areAllSelected ? 'check-square' : 'square'"
            :stroke="
              areAllSelected
                ? 'var(--v-primary-base)'
                : 'var(--v-onSurface_dark-base)'
            "
          />
        </v-btn>
        <div class="title-2 font-weight-bold onSurface_dark--text d-flex">
          전체 선택
        </div>
      </div>
      <v-btn
        max-height="30px"
        min-width="54px"
        elevation="0"
        class="subtitle-2 font-weight-bold onSurface_normal--text px-0"
        @click="action()"
      >
        작업
      </v-btn>
    </div>
    <div
      :class="search && 'pt-3'"
      class="d-flex flex-column px-2 pb-3"
      style="height: 100%; overflow: auto"
    >
      <div
        v-for="(order, index) in orders"
        :key="order.id"
        @click="
          () => {
            // 현재 경로와 쿼리의 id 값이 동일하지 않을 때만 작동
            if ($route.query.id !== order.id) {
              $router.push({
                path: $route.path,
                query: { ...$route.query, id: order.id },
              });
            }
          }
        "
        class="d-flex pa-2 align-center"
        style="border-radius: 4px; gap: 8px"
        :style="
          selectedOrder.id == order.id &&
          'background: var(--v-background_normal-base)'
        "
      >
        <v-btn icon x-small @click="toggleSelect(order, index)">
          <feather
            :type="order.selected ? 'check-square' : 'square'"
            size="18"
            :stroke="
              order.selected
                ? 'var(--v-primary-base)'
                : 'var(--v-onSurface_dark-base)'
            "
          />
        </v-btn>
        <div class="d-flex flex-column" style="gap: 4px; width: 100%">
          <div class="d-flex justify-space-between">
            <div
              class="d-flex subtitle-2 font-weight-bold onSurface_dark--text"
            >
              {{ order.displayName }}
            </div>
            <div class="d-flex caption onSurface_normal--text">
              {{ $moment(order.updatedAt).fromNow() }}
            </div>
          </div>
          <div
            class="d-flex subtitle-2 font-weight-regular onSurface_dark--text"
          >
            {{ order.purpose + " - " + order.file.length + "개 파일" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-ListSideBar",
  props: {
    orders: Array,
    selectedOrder: Object,
    filter: Object,
    search: Boolean,
  },
  computed: {
    areAllSelected() {
      return this.orders.length && this.orders.every((order) => order.selected); // 모든 order가 selected인지 확인
    },
  },
  data() {
    return {};
  },
  methods: {
    toggleSelectAll() {
      const selectAll = !this.areAllSelected; // 모두 선택되어 있으면 해제, 아니면 선택
      this.orders.forEach((order, index) => {
        order.selected = selectAll;
        this.$set(this.orders, index, order);
      });
    },
    toggleSelect(order, index) {
      order.selected = !order.selected;
      this.$set(this.orders, index, order);
    },
    action() {
      if (!this.orders.some((order) => order.selected === true))
        return this.$toasted.global.error("선택된 주문이 없습니다.");
      this.$toasted.global.notice("일괄 작업은 아직 지원하지 않습니다");
    },
  },
};
</script>

<style></style>
