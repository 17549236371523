<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
      width: 100%;
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">
        모델링 결과
      </div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div v-if="display" class="d-flex flex-column" style="gap: 12px">
      <div class="d-flex flex-column" style="gap: 4px">
        <div class="d-flex subtitle-2 onSurface_normal--text">첨부 도면</div>
        <v-row dense>
          <v-col
            cols="4"
            v-for="item in order.modelingResultFile?.filter(
              (v) => v.type != 'stl'
            )"
            :key="item.name"
          >
            <div
              class="d-flex"
              style="
                border-radius: 4px;
                gap: 8px;
                cursor: pointer;
                border: 1px solid var(--v-background_dark-base);
              "
            >
              <v-img
                style="border-radius: 4px"
                :src="previewImage(item)"
                contain
                :aspect-ratio="4 / 3"
              />
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex flex-column" style="gap: 4px">
        <div class="d-flex subtitle-2 onSurface_normal--text">모델 파일</div>
        <v-row dense>
          <v-col
            cols="12"
            v-for="item in order.modelingResultFile?.filter(
              (v) => v.type == 'stl'
            )"
            :key="item.name"
          >
            <div
              class="d-flex pa-2"
              style="
                border-radius: 4px;
                gap: 8px;
                cursor: pointer;
                border: 1px solid var(--v-background_dark-base);
              "
            >
              <div>
                <v-skeleton-loader
                  v-if="!previewImage(item)"
                  style="width: 40px; height: 40px"
                  type="image"
                />
                <v-img
                  v-else
                  style="border-radius: 4px"
                  :src="previewImage(item)"
                  max-width="40"
                  max-height="40"
                />
              </div>
              <div
                class="d-flex flex-column justify-space-around"
                style="
                  gap: 8px;
                  white-space: nowrap;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <div class="subtitle-2 font-weight-bold onSurface_dark--text">
                  {{ item.name }}
                </div>
                <div
                  class="d-flex justify-space-between subtitle-2 font-weight-regular onSurface_dark--text"
                >
                  <div>
                    {{
                      item.size >= 1024 * 1024
                        ? (item.size / 1024 / 1024).toFixed(2) + "MB"
                        : (item.size / 1024).toFixed(0) + "KB"
                    }}
                  </div>
                  <div>
                    {{ item.type.toUpperCase() }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        cols="12"
        @drop.prevent="onFileDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
      >
        <div
          @click="$refs.uploader.click()"
          height="100%"
          :style="{
            backgroundColor: dragover ? 'rgba(0, 0, 0, 0.05)' : '',
          }"
          class="d-flex flex-column text-center pa-2"
          style="
            border: 2px dashed var(--v-primary-base);
            border-radius: 4px;
            gap: 4px;
            cursor: pointer;
          "
        >
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileInput"
            multiple
          />
          <div class="subtitle-2 font-weight-bold">새 파일 업로드</div>
          <div class="mx-auto subtitle-2 text-center">
            클릭 또는 드래그로 내 PC 파일 업로드
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-ResultCard",
  props: { order: Object },
  data() {
    return {
      display: true,
      dragover: false,
    };
  },
  methods: {
    onFileDrop(e) {
      this.dragover = false;
      e.dataTransfer.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
    // 일반 인풋 파일 인식
    onFileInput(event) {
      event.target.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
  },
};
</script>

<style></style>
