var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column pa-3",staticStyle:{"gap":"12px","border-radius":"8px","border":"1px solid var(--v-background_dark-base)","width":"100%"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"title-2 font-weight-bold onSurfae_dark--text"},[_vm._v(" 모델링 결과 ")]),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.display = !_vm.display}}},[_c('feather',{attrs:{"type":_vm.display ? 'chevron-up' : 'chevron-down'}})],1):_vm._e()],1),(_vm.display)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"12px"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px"}},[_c('div',{staticClass:"d-flex subtitle-2 onSurface_normal--text"},[_vm._v("첨부 도면")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.order.modelingResultFile?.filter(
            (v) => v.type != 'stl'
          )),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex",staticStyle:{"border-radius":"4px","gap":"8px","cursor":"pointer","border":"1px solid var(--v-background_dark-base)"}},[_c('v-img',{staticStyle:{"border-radius":"4px"},attrs:{"src":_vm.previewImage(item),"contain":"","aspect-ratio":4 / 3}})],1)])}),1)],1),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px"}},[_c('div',{staticClass:"d-flex subtitle-2 onSurface_normal--text"},[_vm._v("모델 파일")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.order.modelingResultFile?.filter(
            (v) => v.type == 'stl'
          )),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex pa-2",staticStyle:{"border-radius":"4px","gap":"8px","cursor":"pointer","border":"1px solid var(--v-background_dark-base)"}},[_c('div',[(!_vm.previewImage(item))?_c('v-skeleton-loader',{staticStyle:{"width":"40px","height":"40px"},attrs:{"type":"image"}}):_c('v-img',{staticStyle:{"border-radius":"4px"},attrs:{"src":_vm.previewImage(item),"max-width":"40","max-height":"40"}})],1),_c('div',{staticClass:"d-flex flex-column justify-space-around",staticStyle:{"gap":"8px","white-space":"nowrap","overflow":"hidden","width":"100%"}},[_c('div',{staticClass:"subtitle-2 font-weight-bold onSurface_dark--text"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between subtitle-2 font-weight-regular onSurface_dark--text"},[_c('div',[_vm._v(" "+_vm._s(item.size >= 1024 * 1024 ? (item.size / 1024 / 1024).toFixed(2) + "MB" : (item.size / 1024).toFixed(0) + "KB")+" ")]),_c('div',[_vm._v(" "+_vm._s(item.type.toUpperCase())+" ")])])])])])}),1)],1),_c('div',{attrs:{"cols":"12"},on:{"drop":function($event){$event.preventDefault();return _vm.onFileDrop($event)},"dragover":function($event){$event.preventDefault();_vm.dragover = true},"dragenter":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();_vm.dragover = false}}},[_c('div',{staticClass:"d-flex flex-column text-center pa-2",staticStyle:{"border":"2px dashed var(--v-primary-base)","border-radius":"4px","gap":"4px","cursor":"pointer"},style:({
          backgroundColor: _vm.dragover ? 'rgba(0, 0, 0, 0.05)' : '',
        }),attrs:{"height":"100%"},on:{"click":function($event){return _vm.$refs.uploader.click()}}},[_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","multiple":""},on:{"change":_vm.onFileInput}}),_c('div',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v("새 파일 업로드")]),_c('div',{staticClass:"mx-auto subtitle-2 text-center"},[_vm._v(" 클릭 또는 드래그로 내 PC 파일 업로드 ")])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }